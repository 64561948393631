import * as React from "react";

import { BasicPropertyOffers, PropertyLevelOffersMessage, OffersLayouts } from "@shared-ui/lodging-property-offers";
import { RefTarget } from "@shared-ui/ref-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { PropertyOffersProps } from "../typings";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";
import { RoomAvailability } from "../components/RoomAvailability";

const WrappedBasicPropertyOffers = PropertyBexApiWrapper(BasicPropertyOffers);
const WrappedPropertyOffersMessage = PropertyBexApiWrapper(PropertyLevelOffersMessage);

export const PropertyOffersLazy = (props: PropertyOffersProps) => {
  const { context, templateComponent } = props;

  /* istanbul ignore if */
  if (!templateComponent) {
    return null;
  }
  const { layout, showRoomHighlights = false, displayRateCheckForm = false } = templateComponent?.config || {};

  return (
    <UitkSpacing
      margin={{ blockend: "three", blockstart: "six" }}
      padding={{ small: { inline: "three" }, medium: { inline: "unset" } }}
      className="PropertyOffers"
    >
      <RefTarget name="Offers" tag="section" dataStid="section-hotel-search">
        <LazyLoad context={context}>
          <>
            <UitkSpacing margin={{ blockend: "two" }}>
              <WrappedPropertyOffersMessage context={context} />
            </UitkSpacing>
            <UitkSpacing
              margin={
                layout === OffersLayouts.CAROUSEL
                  ? { small: { inline: "three" }, medium: { inline: "unset" } }
                  : undefined
              }
            >
              <div>
                <WrappedBasicPropertyOffers
                  context={context}
                  renderLayout={layout === OffersLayouts.CAROUSEL ? OffersLayouts.CAROUSEL : OffersLayouts.GRID}
                  showRoomHighlights={showRoomHighlights}
                  displayRateCheckForm={displayRateCheckForm}
                >
                  {displayRateCheckForm && <RoomAvailability context={context} />}
                </WrappedBasicPropertyOffers>
              </div>
            </UitkSpacing>
          </>
        </LazyLoad>
      </RefTarget>
    </UitkSpacing>
  );
};

export default PropertyOffersLazy;
